export default {
    PERFORMANCE_OBLIGATION_ORIGIN: {
        name: "REV_DEFERRAL",
        label: "Revenue_Deferral",
        code: "Rev_Def"
    },
    REVENUE: { name: "REVENUE", label: "Revenue Recognition", code: "Rev_Rec" },

    FX_GAIN_LOSS: {
        name: "FX_GAIN_LOSS",
        label: "FX Gain Loss",
        code: "FX_GL"
    },

    COM_DEFERRAL: {
        name: "COM_DEFERRAL",
        label: "Deferred Expense",
        code: "Exp_DE"
    },
    COM_EXPENSE: {
        name: "COM_EXPENSE",
        label: "Expense Recognized",
        code: "Exp_ER"
    },

    EXP_DEFERRAL: {
        name: "EXP_DEFERRAL",
        label: "Deferred Expense",
        code: "Exp_DE"
    },
    EXP_RECOGNIZED: {
        name: "EXP_RECOGNIZED",
        label: "Expense Recognized",
        code: "Exp_ER"
    },

    DEFERRED_REVENUE_RECLASS_TO_UNBILLED_RECEIVABLE: {
        name: "DREV_RECLASS_UR",
        label: "Unbilled Receivable Reclass",
        code: "UR_Recl"
    },
    UNBILLED_RECEIVABLE_REVERSAL: {
        name: "UR_REVERSAL",
        label: "Unbilled Receivable Reclass Reversal",
        code: "UR_Recl_Rvsl"
    },

    TAX_ORIGINAL: {
        name: "TAX_ORIGINAL",
        label: "Tax Original",
        code: "Tax_Org"
    },
    TAX_REVERSAL: {
        name: "TAX_REVERSAL",
        label: "Tax Reversal",
        code: "Tax_Rvsl"
    }
};
