import { createSelector } from "reselect";
import { Utils, moment } from "revlock-webutils";
import { Reference, SSPSelectorHelper } from "revlock-accounting";
import Selectors from "./generic.selector";
import { hasSeenTour } from "../../utils/Crisp";
import { currentUser } from "./user.selector";
import { Currency } from "revlock-accounting";

const { CurrencyType } = Currency;
const { sspSelectorProductFields } = SSPSelectorHelper;

const { RecognitionRule } = Reference;
export const sideNavOpen = (state) =>
    state && state.layout && state.layout.sidenavOpen;
export const products = Selectors["product"].selectAll;
export const customerContracts = Selectors["customerContracts"].selectAll;
export const directExpenses = Selectors["directExpenses"].selectAll;
export const inDirectExpenses = Selectors["inDirectExpenses"].selectAll;
export const customerInvoices = Selectors["customerInvoices"].selectAll;
export const customers = Selectors["customer"].selectAll;
export const journalAccounts = Selectors["journalAccount"].selectAll;
export const clientAttributes = Selectors["clientAttribute"].selectAll;
export const sspSelectors = Selectors["sspSelector"].selectAll;
export const salesPersons = Selectors["salesPerson"].selectAll;
export const recognitionRules = Selectors["recognitionRule"].selectAll;
export const commissionRecognitionRules =
    Selectors["commissionRecognitionRule"].selectAll;
export const professionalServicesLabor =
    Selectors["professionalServicesLabor"].selectAll;
export const allRevenueSummary = Selectors["revenueSummary"].selectAll;
export const allCommissionSummary = Selectors["commissionSummary"].selectAll;
export const standaloneSellingPrice =
    Selectors["standaloneSellingPrice"].selectAll;
export const salesOrders = Selectors["salesOrder"].selectAll;
export const orgConfig = Selectors["orgConfig"].selectAll;
export const recon = Selectors["recon"].selectAll;
export const forexExchange = Selectors["forexExchange"].selectAll;
export const journalAccountMappings =
    Selectors["journalAccountMappings"].selectAll;

export const sync = (state) => state.data && state.data.sync;
export const salesOrderSearchParams = Selectors["salesOrder"].searchParams;
export const pageTitle = (state) => state.data && state.data.pageTitle;
export const customerGridColumns = (state) =>
    state.data && state.data.customerGridColumns;
export const productGridColumns = (state) =>
    state.data && state.data.productGridColumns;
export const salesOrderGridColumns = (state) =>
    state.data && state.data.salesOrderGridColumns;
export const sspGridColumns = (state) =>
    state.data && state.data.sspGridColumns;
export const _sspGridColumns = (state) =>
    state.data && state.data._sspGridColumns;
export const expenseGridColumns = (state) =>
    state.data && state.data.expenseGridColumns;
export const salesOrderItemGridColumns = (state) =>
    state.data && state.data.salesOrderItemGridColumns;
export const revenueArrangementItemGridColumns = (state) =>
    state.data && state.data.revenueArrangementItemGridColumns;
export const commissionItemGridColumns = (state) =>
    state.data && state.data.commissionItemGridColumns;
export const getSalesOrderId = (state, props) => props.salesOrderId;
export const confirmDialogParams = (state) =>
    state.data && state.data.confirmDialogParams;
export const maxIds = (state) => state.data && state.data.maxIds;
export const newRevenueArrangementWizard = (state) =>
    state.data && state.data.newRevenueArrangementWizard;

export const appConfig = (state) => state.data && state.data.appConfig;
export const journalMappings = (state) =>
    state.data && state.data.journalMapping;
export const tasks = (state) => state.data && state.data.tasks;
export const organizations = (state) => state.data && state.data.organizations;

export const currentSalesOrderId = Selectors["salesOrder"].currentId;
export const currentRevenueArrangementId = (state) =>
    state.data && state.data.currentRevenueArrangementId;
export const currentSalesOrderState = (state) =>
    state && state.currentSalesOrder;

// Generic status checking of redux state for loading purpose
export const genericState = (state, props) => state[props.name];
export const genericStateName = (state, props) => props.name;

export const snackbarParams = (state) =>
    state.data && state.data.snackbarParams;

export const doMstrLogout = (state) => state.data && state.data.doMstrLogout;

export const sspUploadResults = (state) =>
    state.data && state.data.sspUploadResults;

export const journalAccountMappingUploadResults = (state) =>
    state.data && state.data.journalAccountMappingUploadResults;
export const journalAccountsUploadResults = (state) =>
    state.data && state.data.journalAccountsUploadResults;
export const taxJournalAccountMappingUploadResults = (state) =>
    state.data && state.data.taxJournalAccountMappingUploadResults;

export const tabIndex = (state, props) =>
    state.data && state.data[`tab_${props.id}`];

export const showProductToursDialog = (state) =>
    state.data && state.data.showProductToursDialog;
export const sspAnalyzerRefresh = (state) =>
    state.data && state.data.sspAnalyzerRefresh;
export const showUpgradeDialog = (state) =>
    state.data && state.data.showUpgradeDialog;
export const showQuickViewBanner = (state) =>
    state.data && state.data.showQuickViewBanner;
export const showSuspendedBanner = (state) =>
    state.data && state.data.showSuspendedBanner;
export const quickViewBanner = (state) =>
    state.data && state.data.quickViewBanner;
export const userFavorites = (state) => state.data && state.data.userFavorites;

export const startRevenueArrangementTour = (state) =>
    state.data && state.data.startRevenueArrangementTour;

export const showSSPBuilderDialog = (state) =>
    state.data && state.data.showSSPBuilderDialog;

export const tourRequested = (state) =>
    state.data && state.data.showWelcomeTour;

export const currentJoyRide = (state) =>
    state.data && state.data.currentJoyRide;

export const showImportDialog = (state) =>
    state.data && state.data.showImportDialog;
export const autoSync = (state) => state.data && state.data.autoSync;
export const syncImportComplete = (state) =>
    state.data && state.data.syncImportComplete;
export const splashOtherMessage = (state) =>
    state.data && state.data.splashOtherMessage;

export const getCurrency = (state) => {
    let toReturn;

    if (
        state.currentOrganization &&
        state.currentOrganization.currentOrganization
    ) {
        const currencyCode =
            state.currentOrganization.currentOrganization.currency;
        if (currencyCode) {
            return CurrencyType[currencyCode];
        }
    }

    if (state.data && state.data.currency) {
        if (Utils.isObject(state.data.currency)) {
            // from dashboard currency is an object.
            toReturn = CurrencyType[state.data.currency.code];
        } else {
            // From Customer page currency is not an object but a string code
            toReturn = CurrencyType[state.data.currency];
        }
    }

    // returning USD currency default if we couldn't find currency
    return toReturn || CurrencyType["USD"];
};

export const formatCurrency = createSelector([getCurrency], (currency) => {
    return (amount, n, x, sign) =>
        Utils.formatCurrency(amount, n, x, sign, currency.symbol);
});

export const triggerTour = createSelector(
    [currentUser, tourRequested],
    (currentUser, tourRequested) => {
        if (tourRequested) return true;

        if (!currentUser) return false;

        if (currentUser) {
            const seenTour = hasSeenTour("welcome_tour", currentUser);
            if (tourRequested === false) return false;

            return seenTour ? false : true;
        }
    }
);

export const joyRide = createSelector([currentJoyRide], (currentJoyRide) => {
    if (currentJoyRide) return currentJoyRide; //"basicTour"
});

export const getAppConfig = createSelector([appConfig], (appConfig) => {
    if (!appConfig) return undefined;

    Utils._currentDate = moment(appConfig[0].asOfDate).toDate();

    return appConfig;
});

export const getJournalAccountMappingConfiguration = createSelector(
    [orgConfig],
    (orgConfig) => {
        if (!orgConfig) return undefined;

        let toReturn = [
            { name: "accountType", displayName: "Journal Account Type" },
            { name: "productCode", displayName: "Product Code" }
        ];

        const customFieldConfig = orgConfig.find(
            (config) => config.id === "customFields/attributeMapping"
        );

        if (customFieldConfig) {
            const customFieldValue = Object.values(customFieldConfig.value);

            customFieldValue.forEach((cfv) => {
                if (
                    ["product", "customer"].includes(cfv.sourceEntity) ||
                    cfv.orderLevel ||
                    !cfv.orderLevel
                ) {
                    // allow everything that is customer, product, orderLevel or item level custom fields
                    toReturn.push(cfv);
                }
            });
        }

        return toReturn;
    }
);

export const lookupJournalAccountMappingFields = (orgConfig, componentType) => {
    if (!orgConfig) {
        return undefined;
    }

    const defaultJamOrgConfigKey = "properties/journal-mapping-fields";
    const jamOrgConfigKey = componentType
        ? `${defaultJamOrgConfigKey}/${componentType.toLowerCase()}`
        : defaultJamOrgConfigKey;
    const defaultCfOrgConfigKey = "customFields/attributeMapping";
    const cfOrgConfigKey = componentType
        ? `${defaultCfOrgConfigKey}/${componentType.toLowerCase()}`
        : defaultCfOrgConfigKey;

    const journalAccountMappingConfig = orgConfig.find(
        (cfg) => cfg.id === jamOrgConfigKey
    );
    const customFieldsConfig = orgConfig.find(
        (config) => config.id == cfOrgConfigKey
    );

    // Other than default mapping keys
    const mappingKeys = journalAccountMappingConfig.value.filter(
        (key) => !["accountType", "productCode"].includes(key)
    );

    // default mapping keys
    mappingKeys.push(
        ...journalAccountMappingConfig.value.filter((key) =>
            ["accountType", "productCode"].includes(key)
        )
    );

    let displayNames =
        (customFieldsConfig &&
            Object.values(customFieldsConfig.value)
                .filter((field) => mappingKeys.includes(field.name))
                .map((field) => field.displayName)) ||
        [];

    journalAccountMappingConfig.value.forEach((key) => {
        if (["productCode"].includes(key)) {
            displayNames.unshift(Utils.convertToNormalCaseFromCamelCase(key));
        }
    });

    displayNames.unshift("Account Type");

    return displayNames;
};

export const getClientJournalAccountMappingFields = createSelector(
    [orgConfig],
    lookupJournalAccountMappingFields
);

export const getOrgConfigurations = createSelector([orgConfig], (orgConfig) => {
    if (!orgConfig) return undefined;

    let toReturn = [];
    orgConfig.forEach((config) => {
        if (
            config.id.includes("properties/") ||
            config.id.includes("integration/") ||
            config.id.includes("customFields/attributeMapping") ||
            config.id.includes("reporting/fields") ||
            config.id.includes("mrr/fields") ||
            config.id.includes("psdCustomFields/attributeMapping") ||
            config.id.includes(
                "billingScheduleCustomFields/attributeMapping"
            ) ||
            config.id.startsWith("ac/")
        ) {
            toReturn.push(config);
        }
    });

    return toReturn;
});

export const getClientReferenceData = createSelector(
    [sspSelectors, orgConfig, standaloneSellingPrice],
    (sspSelectors, orgConfig, standaloneSellingPrice) => {
        if ((!sspSelectors, !orgConfig, !standaloneSellingPrice))
            return undefined;

        return { sspSelectors, orgConfig, standaloneSellingPrice };
    }
);

//sspSelectors, orgConfig, standaloneSellingPrice
export const getClientTablesConfig = createSelector(
    [orgConfig],
    (orgConfig) => {
        if (!orgConfig) return undefined;

        const clientTablesConfig = orgConfig.find(
            (config) => config.id == "properties/client-tables"
        );

        if (!clientTablesConfig || clientTablesConfig.value.length === 0)
            return [];

        return clientTablesConfig.value;
    }
);

export const getStandaloneSellingPrice = createSelector(
    [standaloneSellingPrice],
    (standaloneSellingPrice) => {
        if (!standaloneSellingPrice) return [];

        return standaloneSellingPrice;
    }
);

export const getReconReportsFromS3 = createSelector([recon], (recon) => {
    if (!recon) return undefined;

    return recon;
});

export const getDetailedStandaloneSellingPrice = createSelector(
    [
        standaloneSellingPrice,
        products,
        recognitionRules,
        sspSelectors,
        commissionRecognitionRules
    ],
    (
        standaloneSellingPrice,
        products,
        recognitionRules,
        sspSelectors,
        commissionRecognitionRules
    ) => {
        if (
            !standaloneSellingPrice ||
            !products ||
            !recognitionRules ||
            !sspSelectors ||
            !commissionRecognitionRules
        )
            return undefined;

        const recognitionRuleRef = Object.keys(RecognitionRule).map((key) => {
            return { name: key, id: RecognitionRule[key] };
        });

        standaloneSellingPrice = standaloneSellingPrice.map((ssp) => {
            const sspProduct =
                ssp.attributes &&
                products.find(
                    (p) =>
                        ssp.attributes.ProductCode == p.code ||
                        ssp.attributes.productCode == p.code
                );
            const sspRecogRule = recognitionRuleRef.find(
                (rr) => rr.id == ssp.recognitionRuleId
            ); //recognitionRules.find(rr => rr.id = ssp.recognitionRuleId)
            const sspSelector = sspSelectors.find(
                (ssps) => (ssps.id = ssp.sspSelectorId)
            );

            ssp.priceBook = sspProduct;
            ssp.recognitionRule = sspRecogRule;
            ssp.sspSelector = sspSelector;
            ssp.commissionRecognitionRule = commissionRecognitionRules;

            return ssp;
        });
        return standaloneSellingPrice;
    }
);

export const getInDirectExpenses = createSelector(
    [inDirectExpenses],
    (inDirectExpenses) => {
        if (inDirectExpenses == undefined) return undefined;

        return inDirectExpenses;
    }
);

export const getDirectExpenses = createSelector(
    [directExpenses],
    (directExpenses) => {
        if (directExpenses == undefined) return undefined;

        return directExpenses;
    }
);

export const getCustomerContracts = createSelector(
    [customerContracts],
    (customerContracts) => {
        if (customerContracts == undefined) {
            return undefined;
        }

        return customerContracts;
    }
);

export const getCustomerInvoices = createSelector(
    [customerInvoices],
    (customerInvoices) => {
        if (customerInvoices == undefined) {
            return undefined;
        }

        return customerInvoices;
    }
);

export const getCurrentSalesOrder = createSelector(
    [Selectors["salesOrder"].current],
    (salesOrder) => {
        if (salesOrder == undefined) {
            return undefined;
        }

        return salesOrder;
    }
);

export const currentRevenueArrangement = createSelector(
    [getCurrentSalesOrder, currentRevenueArrangementId],
    (salesOrder, revenueArrangementId) => {
        if (!salesOrder || !revenueArrangementId) {
            return undefined;
        }

        return salesOrder.revenueArrangement.find(
            (ra) => ra.id == revenueArrangementId
        );
    }
);

export const getAllProductNames = createSelector([products], (products) => {
    if (!products) return [];

    let toReturn = new Set();

    products.forEach((p) => toReturn.add(p.name));

    return Array.from(toReturn);
});

export const isProductGroupEnabled = createSelector(
    [orgConfig],
    (orgConfig) => {
        if (!orgConfig || !orgConfig.length) return null;

        const hasProductGroup = orgConfig.find(
            (cfg) => cfg.id === "properties/product-group-enabled"
        );
        return (hasProductGroup && hasProductGroup.value == true) || false;
    }
);

export const getProductCustomFields = createSelector(
    [orgConfig],
    (orgConfig) => {
        if (!orgConfig) return undefined;

        const customFieldsConfig = orgConfig.find(
            (config) => config.id == "customFields/attributeMapping"
        );
        const mrrFields = orgConfig.find(
            (config) => config.id === "mrr/fields"
        ) || [{ value: [] }];
        const sspFields = orgConfig.find(
            (config) => config.id === "properties/ssp-fields"
        ) || [{ value: [] }];

        let allPossibleSSPFields =
            (customFieldsConfig &&
                Utils.copy(Object.values(customFieldsConfig.value))) ||
            [];

        allPossibleSSPFields.push(...sspSelectorProductFields);

        let productCustomFields = allPossibleSSPFields.filter(
            (fieldMetadata) => fieldMetadata.sourceEntity === "product"
        );

        const allMandatoryFields = [...mrrFields.value, ...sspFields.value];
        if (allMandatoryFields.length > 0) {
            allMandatoryFields.forEach((field) => {
                const customField = productCustomFields.find(
                    (pcf) => pcf.name === field
                );
                if (customField) {
                    customField.isMandatory = true;
                }
            });
        }

        return productCustomFields;
    }
);

export const getSalesOrderDateCustomFields = createSelector(
    [orgConfig],
    (orgConfig) => {
        if (!orgConfig) return undefined;

        let fields = [
            {
                name: "orderDate",
                displayName: "Order Date",
                type: "date",
                orderLevel: true
            }
        ];
        const customFieldsConfig = orgConfig.find(
            (config) => config.id == "customFields/attributeMapping"
        );

        if (customFieldsConfig && customFieldsConfig.value) {
            let customFields = Object.values(customFieldsConfig.value);
            customFields = customFields.filter(
                (f) => f.orderLevel && f.type === "date"
            );

            fields = fields.concat(customFields);
        }
        return fields;
    }
);

export const getCustomerCustomFields = createSelector(
    [orgConfig],
    (orgConfig) => {
        if (!orgConfig) return undefined;

        const customFieldsConfig = orgConfig.find(
            (config) => config.id == "customFields/attributeMapping"
        );
        const mrrFields = orgConfig.find(
            (config) => config.id === "mrr/fields"
        ) || [{ value: [] }];
        const sspFields = orgConfig.find(
            (config) => config.id === "properties/ssp-fields"
        ) || [{ value: [] }];

        let allPossibleSSPFields =
            (customFieldsConfig &&
                Utils.copy(Object.values(customFieldsConfig.value))) ||
            [];

        allPossibleSSPFields.push(...sspSelectorProductFields);

        let customerCustomFields = allPossibleSSPFields.filter(
            (fieldMetadata) => fieldMetadata.sourceEntity === "customer"
        );

        const allMandatoryFields = [...mrrFields.value, ...sspFields.value];
        if (allMandatoryFields.length > 0) {
            allMandatoryFields.forEach((field) => {
                const customField = customerCustomFields.find(
                    (ccf) => ccf.name === field
                );
                if (customField) {
                    customField.isMandatory = true;
                }
            });
        }

        return customerCustomFields;
    }
);
export const getSalesOrderCustomFields = createSelector(
    [orgConfig],
    (orgConfig) => {
        if (!orgConfig) return undefined;

        const customFieldsConfig = orgConfig.find(
            (config) => config.id == "customFields/attributeMapping"
        );

        if (customFieldsConfig && customFieldsConfig.value) {
            let customFields = Object.values(customFieldsConfig.value);
            customFields = customFields.filter((f) => f.orderLevel);
            return customFields;
        }
        return [];
    }
);
export const getClientSSPFields = createSelector([orgConfig], (orgConfig) => {
    if (!orgConfig) return undefined;

    const sspFieldsConfig = orgConfig.find(
        (config) => config.id == "properties/ssp-fields"
    );
    const customFieldsConfig = orgConfig.find(
        (config) => config.id == "customFields/attributeMapping"
    );

    let allPossibleSSPFields =
        (customFieldsConfig &&
            Utils.copy(Object.values(customFieldsConfig.value))) ||
        [];
    allPossibleSSPFields.push(...sspSelectorProductFields);

    let clientSSPFields =
        sspFieldsConfig &&
        sspFieldsConfig.value &&
        sspFieldsConfig.value.map((fieldname) =>
            allPossibleSSPFields.find(
                (fieldMetadata) => fieldMetadata.name === fieldname
            )
        );

    return clientSSPFields;
});

export const getBillingCustomFields = createSelector(
    [orgConfig],
    (orgConfig) => {
        if (!orgConfig) return undefined;

        const customFieldsConfig = orgConfig.find(
            (config) =>
                config.id == "billingScheduleCustomFields/attributeMapping"
        );
        let customFields =
            (customFieldsConfig &&
                Utils.copy(Object.values(customFieldsConfig.value))) ||
            [];

        return customFields;
    }
);

export const getStateStatus = createSelector(
    [genericState, genericStateName],
    (genericState, genericStateName) => {
        let toReturn = undefined;
        if (!genericState || !genericState.lastUpdated)
            toReturn = {
                hasLoadedBefore: false,
                loading: true,
                name: genericStateName,
                lastUpdated: genericState.lastUpdated
            };
        else
            toReturn = {
                hasLoadedBefore: true,
                loading: genericState.isFetching ? true : false,
                name: genericStateName,
                lastUpdated: genericState.lastUpdated
            };
        return toReturn;
    }
);

export const getClientCustomReports = createSelector(
    [orgConfig],
    (orgConfig) => {
        if (!orgConfig) return undefined;

        let customReports =
            orgConfig &&
            orgConfig.find(
                (cfg) => cfg.id === "properties/custom-native-reports"
            );

        return (customReports && customReports.value) || [];
    }
);
