export const customPreprocess = (
    orderWithChange,
    refData,
    isApiCall
) => {
    
    const orgConfig = refData.orgConfig
    const scriptNameConfig = orgConfig.find(
        (config) => config.id === "properties/custom-code/preprocess-script-name"
    );
    const scriptName = scriptNameConfig && scriptNameConfig.value;
    const customPreProcessScript = refData.customCode

    if (scriptName && customPreProcessScript && customPreProcessScript[scriptName]) {
        // we have custom code to run here
        const customPreProcessFunction = customPreProcessScript[scriptName];
        // const customPreProcessFunction = LeeEnterprisesPreProcessSalesOrder.preProcessSalesOrderCustom
        // this will modify order
        customPreProcessFunction(
            orderWithChange,
            orgConfig,
            isApiCall
        )
    }
};
